import React from "react";
import Seo from "../components/Seo/seo";
import VideoList from "../components/VideoList/VideoList";

const SydneyArtistsPage = () => {
  const videoArr = [
    "https://www.youtube.com/embed/Kh6U_yxr5JE",
    "https://www.youtube.com/embed/UTjC_zqruyg",
    "https://www.youtube.com/embed/niP-wzV6jSw",
  ];

  return (
    <>
      {" "}
      <Seo
        title="Sydney Music"
        description="Selection of favourite music from Sydney"
      />
      ;
      <VideoList videos={videoArr} />
    </>
  );
};

export default SydneyArtistsPage;

import React from "react";

import * as classes from "./VideoList.module.css";

const VideoList = ({ videos }) => {
  return (
    <div className={classes.worldcontainer}>
      {videos.map((video) => (
        <div className={classes.videoContainer} key={video}>
          <iframe
            width="560"
            height="315"
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default VideoList;
